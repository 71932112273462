import { disableSubscriptions, enableSubscriptions } from '@/api/pmsubscription';

const disableSubscription = {
	id: 'disableSubscription',
	selectionType: 'multiple',
	label: 'pmsubscription.actions.disableSubscription',
	functionality: 'UPDATE_PMSUBSCRIPTION',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmsubscription.actions.disableSubscription');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmsubscriptionid: registries[i].pmsubscriptionid
			});
		}

		const data = await disableSubscriptions(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableSubscription = {
	id: 'enableSubscription',
	selectionType: 'multiple',
	label: 'pmsubscription.actions.enableSubscription',
	functionality: 'UPDATE_PMSUBSCRIPTION',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmsubscription.actions.enableSubscription');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmsubscriptionid: registries[i].pmsubscriptionid
			});
		}

		const data = await enableSubscriptions(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableSubscription, enableSubscription]
};
